import { hide, show, toggle } from 'slidetoggle';

const target = document.querySelector('[data-checkout-total-toggle]');
const inner = document.querySelector('[data-checkout-total-inner]');

let opened = true;

if (target && inner) {
  target.addEventListener('click', (event) => {
    event.preventDefault();

    opened = !opened;

    toggle(inner, {
      onAnimationEnd: () => {
        target.setAttribute('data-checkout-total-toggle', opened.toString());
        inner.setAttribute('data-checkout-total-inner', opened.toString());
      },
    });
  });
}
