const header = document.querySelector('.header');
const toggles = document.querySelectorAll(
  '[data-bs-toggle="dropdown"]:not(.dropdown-simple)',
);

const isInitialLock =
  header && header.getAttribute('data-sticky-lock') === 'true';

toggles.forEach((toggle) => {
  const dropdown = new bootstrap.Dropdown(toggle, {
    autoClose: 'outside',
  });

  toggle.addEventListener('shown.bs.dropdown', () => {
    setTimeout(() => {
      document.body.classList.add('dropdown-overlay');
      header.classList.add('header-sticky');
      header.setAttribute('data-sticky-lock', 'true');
    }, 0);
  });

  toggle.addEventListener('hide.bs.dropdown', () => {
    if (!isInitialLock) {
      header.setAttribute('data-sticky-lock', 'false');
    }

    document.body.classList.remove('dropdown-overlay');
  });
});
