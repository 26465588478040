const timers = document.querySelectorAll('[data-countdown]');

timers.forEach((timer) => {
  const endDate = timer.dataset.countdown;

  const x = setInterval(function () {
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the countdown date
    const distance = new Date(endDate) - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element
    // Format: Ends in 1d • 02:05:58
    if (days > 0) {
      timer.innerHTML = days + 'd • ' + hours + ':' + minutes + ':' + seconds;
    } else {
      timer.innerHTML = hours + ':' + minutes + ':' + seconds;
    }

    // If the countdown is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      timer.remove();
    }
  }, 1000);
});
