const mobileMenu = document.querySelector('.mobile-menu');
const toggles = document.querySelectorAll('.navbar-toggler');

if (mobileMenu) {
  const screens = document.querySelectorAll('[data-screen]');
  const open = document.querySelectorAll('[data-screen-open]');

  open.forEach((button) =>
    button.addEventListener('click', (event) => {
      const name = event.currentTarget.dataset.screenOpen;

      screens.forEach((screen) => {
        if (screen.dataset.screen === name) {
          screen.classList.add('d-flex');
          screen.classList.remove('d-none');
        } else {
          screen.classList.add('d-none');
          screen.classList.remove('d-flex');
        }
      });

      mobileMenu.scrollTo(0, 0);
    }),
  );

  toggles.forEach((toggle) => {
    toggle.addEventListener('click', (event) => {
      event.preventDefault();

      document.body.classList.toggle('nav-open');

      screens.forEach((screen) => {
        screen.classList.toggle('d-flex', screen.dataset.screen === 'root');
        screen.classList.toggle('d-none', screen.dataset.screen !== 'root');
      });
    });
  });
}
