import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/swiper-bundle.css';

const thumbs = new Swiper(
  document.querySelector('[data-product-gallery-thumbs]'),
  {
    modules: [Thumbs, Navigation],
    slidesPerView: 5,
    spaceBetween: 10,
    direction: 'vertical',
    loop: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.product-gallery-thumbs-arrow',
    },
  },
);

const gallery = new Swiper(document.querySelector('[data-product-gallery]'), {
  modules: [Navigation, Pagination, Thumbs],
  slidesPerView: 1,
  spaceBetween: 10,
  loop: false,
  navigation: {
    prevEl: '[data-product-gallery-prev]',
    nextEl: '[data-product-gallery-next]',
  },
  pagination: {
    el: '.swiper-pagination',
  },
  thumbs: {
    swiper: thumbs,
  },
});
