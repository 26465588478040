const selects = document.querySelectorAll('[data-quantity-select]');

const priceElements = document.querySelectorAll('.js-price-element');
const savePriceElements = document.querySelectorAll('.js-save-price-element');
const discountPriceElements = document.querySelectorAll(
  '.js-discount-price-element',
);

selects.forEach((select) => {
  select.addEventListener('change', (event) => {
    const { price, savePrice, discountPrice } = event.target.dataset;

    if (price) {
      priceElements.forEach((element) => {
        element.innerHTML = price;
      });
    } else {
      priceElements.forEach((element) => {
        element.innerHTML = '';
      });
    }

    if (savePrice) {
      savePriceElements.forEach((element) => {
        element.innerHTML = savePrice;
      });
    } else {
      savePriceElements.forEach((element) => {
        element.innerHTML = '';
      });
    }

    if (discountPrice) {
      discountPriceElements.forEach((element) => {
        element.innerHTML = discountPrice;
      });
    } else {
      discountPriceElements.forEach((element) => {
        element.innerHTML = '';
      });
    }
  });
});
