const containers = document.querySelectorAll('.more-less-text');

const activeClass = 'more-less-text-active';
const toggleActiveClass = 'more-less-text-toggle-active';

containers.forEach((container) => {
  container.addEventListener('click', (event) => {
    event.preventDefault();

    const toggle = container.querySelector('.more-less-text-toggle');

    toggle.classList.toggle(toggleActiveClass);
    toggle.innerText = container.classList.contains(activeClass)
      ? 'more'
      : 'less';
    container.classList.toggle(activeClass);
  });
});
