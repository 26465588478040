import Clipboard from 'clipboard';

const clipboard = new Clipboard('[data-clipboard-text]');

clipboard.on('success', (event) => {
  const originalText = event.trigger.textContent;

  event.trigger.innerHTML = '<span>Copied!</span>';

  setTimeout(() => {
    event.trigger.innerHTML = `<span>${originalText}</span>`;
  }, 2000);
});
