const buttons = document.querySelectorAll('[data-append-tag]');

buttons.forEach((button) =>
  button.addEventListener('click', (event) => {
    event.preventDefault();

    const inputs = document.querySelectorAll('.tpx-search-form .form-control');

    inputs.forEach((input) => {
      input.value = event.currentTarget.dataset.appendTag;
    });
  }),
);
