import iMask from 'imask';
import get from 'lodash/get';

const inputs = document.querySelectorAll('[data-input-mask]');

const masks = {
  phone: '+7 (999) 999-99-99',
  card: '0000 0000 0000 0000',
  card_date: '00 / 00',
  cvv: '000',
};

const maskPlaceholder = {
  default: '',
  card: ' · ',
  card_date: 'MM / YY',
};

const inputMaskLazy = {
  default: false,
  card: true,
  card_date: true,
  cvv: true,
};

inputs.forEach((input) => {
  const mask = get(masks, input.dataset.inputMask, '');
  const placeholderChar = get(maskPlaceholder, input.dataset.inputMask, '');
  const lazy = get(inputMaskLazy, input.dataset.inputMask, false);

  const imask = new iMask(input, {
    mask,
    placeholderChar,
    lazy,
  });
});
