import SimpleBar from 'simplebar';

const header = document.querySelector('.header');
const toggle = document.querySelector('.industries-dropdown-toggle');
const menu = document.querySelector('.industries-dropdown');
const tests = document.querySelectorAll('.industries-dropdown-tests');

const isInitialLock =
  header && header.getAttribute('data-sticky-lock') === 'true';

if (menu && toggle) {
  const dropdown = new bootstrap.Dropdown(toggle, {
    autoClose: 'outside',
  });

  toggle.addEventListener('show.bs.dropdown', () => {
    header.classList.add('header-sticky');
    header.setAttribute('data-sticky-lock', 'true');
    document.body.classList.add('dropdown-overlay');
  });

  toggle.addEventListener('hide.bs.dropdown', () => {
    if (window.scrollY === 0) {
      if (!isInitialLock) {
        header.classList.remove('header-sticky');
      }
    }

    if (!isInitialLock) {
      header.setAttribute('data-sticky-lock', 'false');
    }

    document.body.classList.remove('dropdown-overlay');
  });

  const screens = document.querySelectorAll('[data-sub-menu]');
  const open = document.querySelectorAll('[data-sub-menu-open]');
  const close = menu.querySelector('.btn-close');

  close.addEventListener('click', () => {
    dropdown.hide();
  });

  open.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault();

      const name = event.currentTarget.dataset.subMenuOpen;

      open.forEach((item) => {
        item.classList.remove('active');
      });

      button.classList.add('active');

      screens.forEach((screen) => {
        if (screen.dataset.subMenu === name) {
          screen.classList.add('d-flex');
          screen.classList.remove('d-none');

          const simplebar = SimpleBar.instances.get(screen);

          const scroll = simplebar.getScrollElement();
          const content = simplebar.getContentElement();

          if (scroll.clientHeight < content.clientHeight) {
            screen.classList.add('scroll');
          }
        } else {
          screen.classList.add('d-none');
          screen.classList.remove('d-flex');
        }
      });
    });
  });

  tests.forEach((test) => {
    const simplebar = new SimpleBar(test, {
      autoHide: false,
    });
  });
}
