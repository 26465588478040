// const accordion = document.querySelector('.accordion.accordion-payment');
//
// if (accordion) {
//   const buttons = accordion.querySelectorAll('.accordion-button');
//
//   buttons.forEach((button) => {
//     const item = button.parentElement.parentElement;
//     const instance = bootstrap.Collapse.getOrCreateInstance(item);
//
//     console.log(item);
//
//     item.addEventListener('hide.bs.collapse', (event) => {
//       if (button.getAttribute('aria-expanded') === 'true') {
//         event.preventDefault();
//       }
//     });
//   });
// }
