import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

const swiper = new Swiper('[data-component="blog-slider"]', {
  modules: [Navigation],
  slidesPerView: 1.1,
  spaceBetween: 10,
  simulateTouch: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
  },
});
