import 'fslightbox';

import './bootstrap';
import './components/sticky-header';
import './components/input-mask';
import './components/quantity-select';
import './components/product-gallery';
import './components/select';
import './components/phone-input';
import './components/dropdown';
import './components/simplebar';
import './components/countdown';
import './components/quantity-dropdown';
import './components/industries-dropdown';
import './components/blog-slider';
import './components/tags-append';
import './components/copy-to-clipboard';
import './components/more-less-text';
import './components/checkout-total-toggle';
import './components/card-input';
import './components/mobile-menu';
import './components/payment-method-accordion';
