const dropdowns = document.querySelectorAll('[data-dropdown-quantity]');

dropdowns.forEach((dropdown) => {
  const list = dropdown.querySelector('[data-dropdown-quantity-list]');
  const items = list.querySelectorAll('.cart-quantity-dropdown');

  items.forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();

      const trigger = dropdown.firstElementChild;

      items.forEach((item) => {
        item.classList.remove('hidden');
      });

      const clone = item.cloneNode(true);
      clone.setAttribute('data-bs-toggle', 'dropdown');
      clone.setAttribute('aria-expanded', 'false');

      trigger.replaceWith(clone);

      const dropdownInstance = bootstrap.Dropdown.getOrCreateInstance(clone);

      item.classList.add('hidden');

      dropdownInstance.hide();
    });
  });
});
