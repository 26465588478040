import Choices from 'choices.js';

const selects = document.querySelectorAll(
  'select.form-control.form-control-custom-select',
);

selects.forEach((select) => {
  const choices = new Choices(select, {
    searchEnabled: false,
    itemSelectText: '',
    position: 'bottom',
  });
});
